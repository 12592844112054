import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import groupsReducer from './groupsReducer';
import groupSitesReducer from './groupSitesReducer';
import groupTypesReducer from './groupTypesReducer';
import pseudoGroupSecurityGroupsReducer from './pseudoGroupSecurityGroupsReducer';
import regionsReducer from './regionsReducer';
import sitesReducer from './sitesReducer';
import commoditiesReducer from './commoditiesReducer';
import siteTypesReducer from './siteTypesReducer';
import siteInfoReducer from './siteInfoReducer';
import { usersReducer } from './usersReducers';
import countriesReducer from './countriesReducer';
import siteGroupsReducer from './siteGroupsReducer';
import subsiteInfoReducer from './subsiteInfoReducer';
import {cognitoGroupsReducer} from './cognitoGroupsReducer'

// The combineReducers helper function turns an object whose values are different "slice reducer"
// functions into a single combined reducer function you can pass to Redux Toolkit's configureStore 
// (or the legacy createStore method)

// The resulting combined reducer calls every slice reducer any time an action is dispatched, 
// and gathers their results into a single state object. 
// This enables splitting up reducer logic into separate functions, 
// each managing their own slice of the state independently.

export default combineReducers({
	toastr: toastrReducer,
	Groups: groupsReducer,
	groupTypes: groupTypesReducer,
	pseudoGroupSecurityGroups: pseudoGroupSecurityGroupsReducer,
	regions: regionsReducer,
	countries: countriesReducer,
	commodities: commoditiesReducer,
	sites: sitesReducer,
	siteTypes: siteTypesReducer,
	siteInfo: siteInfoReducer,
	users: usersReducer,
	siteGroups: siteGroupsReducer,
	groupSites: groupSitesReducer,
	subsitesInfo: subsiteInfoReducer,
	cognitoGroups: cognitoGroupsReducer
	// usersProduction: usersProductionReducer,
	// userProcess: userProcessReducer
});
