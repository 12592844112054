import {
	FETCH_COGNITO_GROUPS,
} from 'actions';

const cognitoGroupsReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_COGNITO_GROUPS:
			return action.payload;
		default:
			return state;
	}
};

export {cognitoGroupsReducer}