import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_USERS,
	FETCH_USERS_ERR,
	FETCH_USER,
	FETCH_USER_ERR,
	APPROVE_USER,
	APPROVE_USER_ERR,
	DENY_USER,
	DENY_USER_ERR,
	DELETE_USER,
	DELETE_USER_ERR,
	UPDATE_USER,
	UPDATE_USER_ERR,
	FETCH_COGNITO_GROUPS,
	FETCH_COGNITO_GROUPS_ERR,
	FETCH_COGNITO_GROUP_USER,
	FETCH_COGNITO_GROUP_USER_ERR,
	SEND_REPORT,
	SEND_REPORT_ERR,
	SEND_USER_REPORT,
	SEND_USER_REPORT_ERR
} from 'actions';
import config from 'config';
import { replacer } from 'utils';

export const sendGAReport = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.sendReport, {
		headers: {}
	});

	return request.then(
		(reponse) =>
			dispatch({
				type: SEND_REPORT,
				payload: reponse
			}),
		(err) =>
			dispatch({
				type: SEND_REPORT_ERR,
				payload: err
			})
	);
};

export const sendUserSpecificGAReport = (payload) => async (dispatch) => {
	const id = payload.id;
	const type = payload.type;
	const request = API.get(
		'gains-admin-api',
		`${replacer(config.apiEndpoints.sendUserGAReport, { id })}?type=${type}`,
		{
			headers: {}
		}
	);

	return request.then(
		(reponse) => {
			dispatch({
				type: SEND_USER_REPORT,
				payload: reponse
			});
			toastr.success("Sending report..", "Report successfully sent to your email")
		},
		(err) => {
			dispatch({
				type: SEND_USER_REPORT_ERR,
				payload: err
			})
			toastr.error("Sending report..", "Error sending report..")}
	);
};

export const fetchCognitoGroups = () => async (dispatch) => {
	const request = API.get(
		'gains-admin-api',
		config.apiEndpoints.cognitoGroups,
		{
			headers: {}
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_COGNITO_GROUPS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_COGNITO_GROUPS_ERR,
				payload: err
			})
	);
};

export const fetchCognitoGroupUser = (payload) => async (dispatch) => {
	const id = payload;

	const request = API.get(
		'gains-admin-api',
		replacer(config.apiEndpoints.cognitoGroupUser, {
			id
		}),
		{
			headers: {}
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_COGNITO_GROUP_USER,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_COGNITO_GROUP_USER_ERR,
				payload: err
			})
	);
};

export const addCognitoGroupUser = (payload) => async (dispatch) => {
	let id = payload['id'];
	let groupName = payload['groupName'];

	const request = API.post(
		'gains-admin-api',
		replacer(config.apiEndpoints.cognitoGroupUser, {
			id
		}),
		{
			headers: {},
			body: { group: groupName }
		}
	);

	return request.then(
		(response) => {
			console.log(response);
			dispatch({
				type: FETCH_COGNITO_GROUP_USER,
				payload: response
			});
			toastr.success(payload['toaster_title'], payload['toaster_feedback']);
		},
		(err) => {
			console.log(err);
			dispatch({
				type: FETCH_COGNITO_GROUP_USER_ERR,
				payload: err
			});
			toastr.error('User not found', 'Could not add Cognito Group for user');
		}
	);
};

export const deleteCognitoGroupUser = (payload) => async (dispatch) => {
	let id = payload['id'];
	let groupName = payload['groupName'];

	const endpointWithSuffix =
		replacer(config.apiEndpoints.cognitoGroupUser, { id }) + '/' + groupName;

	const request = API.del('gains-admin-api', endpointWithSuffix, {
		headers: {}
	});

	return request.then(
		(response) => {
			console.log(response);
			dispatch({
				type: FETCH_COGNITO_GROUP_USER,
				payload: response
			});
			toastr.success(payload['toaster_title'], payload['toaster_feedback']);
		},
		(err) => {
			console.log(err);
			dispatch({
				type: FETCH_COGNITO_GROUP_USER_ERR,
				payload: err
			});
			toastr.error('User not found', 'Could not remove Cognito Group for user');
		}
	);
};

export const denyUser = (payload) => async (dispatch) => {
	let body = {
		userRole: 'denied'
	};

	let id = payload['id'];

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateUser, {
			id
		}),
		{
			headers: {},
			body: body
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DENY_USER,
				payload: response
			});
			toastr.success(payload['toaster_title'], payload['toaster_feedback']);
		},
		(err) => {
			console.log(err);
			dispatch({
				type: DENY_USER_ERR,
				payload: err
			});
			toastr.error('User not found', 'This email address was not found');
		}
	);
};

export const approveUser = (payload) => async (dispatch) => {
	let body = {
		userRole: 'user',
		enabled: 'true'
	};

	let id = payload['id'];

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateUser, {
			id
		}),
		{
			headers: {},
			body: body
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: APPROVE_USER,
				payload: response
			});
			toastr.success(payload['toaster_title'], payload['toaster_feedback']);
		},
		(err) => {
			console.log(err);
			dispatch({
				type: APPROVE_USER_ERR,
				payload: err
			});
			toastr.error('User not found', 'This email address was not found');
		}
	);
};

export const updateUser = (payload) => async (dispatch) => {
	let body = {
		userRole: payload['userRole'],
		enabled: payload['enabled']
	};
	let id = payload['id'];

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateUser, {
			id
		}),
		{
			headers: {},
			body: body
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_USER,
				payload: response
			});

			dispatch(fetchUsers());
			toastr.success(payload['toaster_title'], payload['toaster_feedback']);
		},
		(err) => {
			console.log(err);
			dispatch({
				type: UPDATE_USER_ERR,
				payload: err
			});
			toastr.error('User not found', 'This email address was not found');
		}
	);
};

export const deleteUser = (payload) => async (dispatch) => {
	const { email } = payload;
	let id = payload['id'];

	const request = API.del(
		'gains-admin-api',
		replacer(config.apiEndpoints.deleteUser, {
			id
		}),
		{
			headers: {}
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_USER,
				payload: response
			});
			dispatch(fetchUsers());
			toastr.success('Delete User', `User "${email}" deleted successfully`);
		},
		(err) => {
			dispatch({
				type: DELETE_USER_ERR,
				payload: err
			});
			toastr.error('Delete User', 'There was an error deleting the User');
		}
	);
};

export const fetchUsers = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.users, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_USERS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_USERS_ERR,
				payload: err
			})
	);
};

export const fetchUser = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.get('gains-admin-api', config.apiEndpoints.user, {
		headers: {},
		body: id
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_USER,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_USER_ERR,
				payload: err
			})
	);
};
